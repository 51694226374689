import { getBitcoinFees } from "@/utils"
import { useRequest } from "ahooks"

export const useFeeRate = () => {
  const { data } = useRequest(async () => {
    const res = await getBitcoinFees()
    const keys = Object.keys(res).filter((key) => Number(key) <= 10)
    const feeRate = keys?.length ? keys.map((item: string) => {
      return { key: Number(item), value: Number(res[item].toFixed(2)) }
    }) : []

    return feeRate
  }, {
    ready: true,
    pollingInterval: 60000,
    onSuccess: (res) => res
  }
  )

  return {
    feeRateOptions: data,
  }
}