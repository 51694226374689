'use client'

import * as React from 'react'
import * as SeparatorPrimitive from '@radix-ui/react-separator'

import { ny } from '@/utils/cn'

const Separator = React.forwardRef<React.ElementRef<typeof SeparatorPrimitive.Root>, React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>>(
  ({ className, orientation = 'horizontal', decorative = true, ...props }, ref) => (
    <SeparatorPrimitive.Root
      ref={ref}
      decorative={decorative}
      orientation={orientation}
      className={ny(
        'shrink-0 relative',
        'after:content-["_"] after:bg-border after:left-0 after:top-0 after:absolute',
        orientation === 'horizontal' ? 'h-px w-full after:w-full after:scale-y-50 after:h-px' : 'h-full w-px after:h-full after:scale-x-50 after:w-px',
        className
      )}
      {...props}
    />
  )
)
Separator.displayName = SeparatorPrimitive.Root.displayName

export { Separator }
