'use client'

import * as React from 'react'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { X } from 'lucide-react'
import { cn, ny } from '@/utils/cn'
import { Separator } from '../separator'
import { useState } from 'react'
import { Button, ButtonProps } from '../button'

const Modal = DialogPrimitive.Root

const ModalTrigger = DialogPrimitive.Trigger

const ModalPortal = DialogPrimitive.Portal

const ModalClose = DialogPrimitive.Close

const ModalOverlay = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Overlay>, React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>>(({ className, ...props }, ref) => (
   <DialogPrimitive.Overlay
      ref={ref}
      className={ny('data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0  fixed inset-0 z-50 bg-black/80', className)}
      {...props}
   />
))
ModalOverlay.displayName = DialogPrimitive.Overlay.displayName

const ModalContent = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Content>, React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>>(({ className, children, ...props }, ref) => (
   <ModalPortal>
      <ModalOverlay />
      <DialogPrimitive.Content
         ref={ref}
         className={ny(
            'bg-background data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] fixed left-1/2 top-1/2 z-50 grid w-[calc(100%-32px)] max-w-[928px] -translate-x-1/2 -translate-y-1/2 gap-[60px]  pt-6 px-2.5 pb-8 shadow-lg duration-200 rounded-[16px] sm:px-8 sm:rounded-[20px]',
            className
         )}
         {...props}
      >
         {children}
         {/* <DialogPrimitive.Close className="ring-offset-background focus:ring-ring data-[state=open]:bg-accent data-[state=open]:text-muted-foreground absolute right-4 top-4 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none">
            <X className="size-4" />
            <span className="sr-only">Close</span>
         </DialogPrimitive.Close> */}
      </DialogPrimitive.Content>
   </ModalPortal>
))
ModalContent.displayName = DialogPrimitive.Content.displayName

function ModalHeader({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
   return (
      <div className={ny('flex flex-col text-left', className)} {...props}>
         {props.children}

         <Separator className="!mt-8" />
      </div>
   )
}
ModalHeader.displayName = 'ModalHeader'

function ModalFooter({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
   return <div className={ny('flex flex-col-reverse gap-2 sm:flex-row sm:justify-end', className)} {...props} />
}
ModalFooter.displayName = 'ModalFooter'

const ModalTitle = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Title>, React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>>(({ className, ...props }, ref) => (
   <DialogPrimitive.Title ref={ref} className={ny('text-[20px] leading-normal text-foreground font-normal tracking-tight', className)} {...props} />
))
ModalTitle.displayName = DialogPrimitive.Title.displayName

const ModalDescription = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Description>, React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>>(({ className, ...props }, ref) => (
   <DialogPrimitive.Description ref={ref} className={ny('text-muted-foreground text-sm', className)} {...props} />
))
ModalDescription.displayName = DialogPrimitive.Description.displayName

function ModalAction({
   ...props
}: {
   cancel?:
   | {
      className?: string
      label?: React.ReactNode
      cb?: () => {}
   } & ButtonProps
   | false
   confirm?: {
      className?: string
      label?: React.ReactNode
      cb?: () => void
   } & ButtonProps
   hide?: () => void
}) {
   const [confirmLoading, setConfirmLoading] = useState(false)
   const [cancelLoading, setCancelLoading] = useState(false)
   return (
      <>
         {props.cancel !== false ? (
            <>
               <Button
                  variant={'secondary'}
                  loading={cancelLoading}
                  type='button'
                  {...props.cancel}
                  onClick={async () => {
                     if (props.cancel === false) {
                        return
                     }

                     setCancelLoading(true)

                     try {
                        await Promise.resolve(props.cancel?.cb?.())
                        props.hide?.()
                     } finally {
                        setCancelLoading(false)
                     }
                  }}
                  className={cn('rounded-full', props.cancel?.className)}
                  /** @ts-ignore */
                  disabled={props.cancel?.disabled || props.confirm?.loading}
               >
                  {props.cancel?.label ?? 'Cancel'}
               </Button>
            </>
         ) : null}

         {props.confirm && (
            <>
               <Button
                  loading={confirmLoading}
                  variant={'default'}
                  type='button'
                  {...props.confirm}
                  onClick={props.confirm.cb ? async () => {
                     setConfirmLoading(true)

                     try {
                        await Promise.resolve(props.confirm?.cb?.())
                        props?.hide?.()
                     } finally {
                        setConfirmLoading(false)
                     }
                  } : undefined}
                  className={cn('rounded-full', props.confirm?.className)}
                  /** @ts-ignore */
                  disabled={props.confirm?.disabled || props.cancel?.loading}
               >
                  {props.confirm?.label ?? 'Confirm'}
               </Button>
            </>
         )}
      </>
   )
}
ModalAction.displayName = 'ModalAction'

export { Modal, ModalAction, ModalPortal, ModalOverlay, ModalClose, ModalTrigger, ModalContent, ModalHeader, ModalFooter, ModalTitle, ModalDescription }
