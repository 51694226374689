import { cn } from '@/utils/cn'
import { PropsWithChildren } from 'react'

export const CardContainer = ({ title, children, className }: PropsWithChildren<{ title: React.ReactNode; className?: string }>) => {
  return (
    <div className={cn('flex flex-col flex-1 rounded-[15px] bg-[#101417] p-5', className)}>
      <div className="font-medium text-[18px] leading-[22px]">{title}</div>

      <div className={cn('flex-1 mt-3.5 bg-black rounded-[5px]')}>{children}</div>
    </div>
  )
}
