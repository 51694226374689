import { useMemo } from 'react'
import { getOrdersApi, OpenOrderRecord } from '@/request/counterparty'
import { useQuery } from '@tanstack/react-query'
import { addressAtom } from '@/store'
import { useAtomValue } from 'jotai'
import { formatDecimals } from '@/utils/format-decimals'
import { formatUnit } from '@/utils/format'

export type FormatedOpenOrder = OpenOrderRecord & {
  address_label?: string

  give_remaining_str: string
  give_quantity_str: string
  give_asset_display: string

  get_asset_display: string
  get_quantity_str: string
  get_remaining_str: string
}

export const useOpenOrdersFormatedData = () => {
  const userAddress = useAtomValue(addressAtom)
  const addressList = [userAddress as string]

  const { data = [], ...rest } = useQuery({
    enabled: true,
    refetchOnMount: 'always',
    queryKey: [{ key: 'getOrdersApi', addressList }],
    queryFn: async () => {
      const rs = await getOrdersApi(addressList)

      return rs || []
    },
  })

  const formatedOpenOrders = useMemo(() => {
    const formatedData = data.map((item) => {
      const give_asset_display = formatUnit(item.give_asset)
      const get_asset_display = formatUnit(item.get_asset)

      const formatedItem: FormatedOpenOrder = {
        ...item,
        address_label: userAddress,
        give_asset_display: give_asset_display,
        give_quantity_str: formatDecimals(item.give_quantity, {
          unit: give_asset_display,
          decimals: 8,
          volScale: 8,
        }),
        give_remaining_str: formatDecimals(item.give_remaining, {
          unit: give_asset_display,
          decimals: 8,
          volScale: 8,
        }),

        get_asset_display,
        get_quantity_str: formatDecimals(item.get_quantity, {
          unit: get_asset_display,
          decimals: 8,
          volScale: 8,
        }),
        get_remaining_str: formatDecimals(item.get_remaining, {
          unit: get_asset_display,
          decimals: 8,
          volScale: 8,
        }),
      }

      return formatedItem as FormatedOpenOrder
    })

    return formatedData
  }, [data, userAddress])

  return { formatedOpenOrders, ...rest }
}
