import React from 'react'
import styles from './index.module.scss'

export type TableColumnType = {
  dataIndex: string,
  label: string | React.ReactElement,
  render?: (record: any) => React.ReactElement | string | number
  align?: 'left' | 'center' | 'right',
  width?: number | string
}

export const Table = ({ columns, data, noData }: { columns: TableColumnType[], data: any[], noData?: string | React.ReactElement }) => {
  return (
    <div>
      <table className={styles.table}>
        <thead className={styles.tbaleHead}>
          {
            columns.map((column: TableColumnType) => (<td key={column.dataIndex}>
              <div className={styles.tableHeadItem} style={{ width: column.width }}>
                {column.label}
              </div>
            </td>))
          }
        </thead>
        {
          !!data.length && <tbody className={styles.tableBody}>
            {
              data.map((item: any, index: number) => (
                <tr key={index}>
                  {
                    columns.map((column: TableColumnType) => {

                      return (
                        <td key={column.dataIndex + index}>
                          {
                            column.render ? column.render(item) : (
                              <div className={styles.dataItem}>
                                {item[column.dataIndex]}
                              </div>
                            )
                          }
                        </td>
                      )
                    })
                  }
                </tr>
              ))
            }
          </tbody>
        }

      </table>
      {
        !data.length && noData ? noData : null
      }
    </div>
  )
}
