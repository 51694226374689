import { useRequest } from "ahooks"
import { BuyOrdersForm } from "./buy"
import { SellOrdersForm } from "./sell"
import { getMarketPriceHistory } from "@/request/api"
import { useParams } from "react-router-dom"
import { useState } from "react"

export const OrderForm = () => {
  let { pairSymbol = '' } = useParams()
  const [pairBaseSymbol, pairQuoteSymbol] = pairSymbol?.split('_')
  const [list, setList] = useState<any[]>([])
  
  useRequest(async () => await getMarketPriceHistory(pairBaseSymbol, pairQuoteSymbol), {
    ready: !!pairBaseSymbol && !!pairQuoteSymbol,
    refreshDeps: [pairBaseSymbol, pairQuoteSymbol],
    onSuccess: (res: any) => {
      setList(() => res)
    }
  })

  return (
    <div className="flex gap-6 flex-wrap">
      <BuyOrdersForm marketList={list}/>
      <SellOrdersForm marketList={list}/>
    </div>
  )
}
