import styles from '../index.module.scss'
import { useState } from 'react'
import { SendDialog } from '@/components/SendDialog'
import { useAtomValue } from 'jotai'
import { addressAtom } from '@/store'
import { AssetInfo } from '@/request/asset'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui-shadcn/dropdown-menu'
import { MoreHorizontal } from 'lucide-react'
import { Button } from '@/components/ui-shadcn/button'
import { AssetsInfoModal } from './info-dialog'
import { NiceModal } from '@/components/dialog'
import { Dialog } from '@/components/ui/NewDialog'
import { IssueAdditionalDialog } from '@/components/IssueAdditionalDialog'
import { ChangeTokenDescriptionDialog } from '@/components/ChangeTokenDescriptionDialog'
import { LockTokenDialog } from '@/components/LockTokenDialog'
import { TransferTokenDialog as TransferDialog } from '@/components/TransferTokenDialog'

export type TokenItemType = {
  tokenType: string | TokenType,
  balance: string
  formattedBalance: string
  asset: string
  address: string
  unconfirmedBalance?: string
}

export enum TokenType {
  BTC = 'BTC',
  BRT = 'BRT',
}

export const TokenItem = ({ token, assetInfo, tokenAssets }: { token: TokenItemType, assetInfo?: AssetInfo, tokenAssets: string[] }) => {
  const [openSend, setOpenSend] = useState<boolean>(false)
  const [openIssueAdditional, setOpenIssueAdditional] = useState<boolean>(false)
  const [changeTokenDescription, setChangeTokenDescription] = useState<boolean>(false)
  const [lockToken, setLockToken] = useState<boolean>(false)
  const [transferOpen, setTransferOpen] = useState<boolean>(false)

  const userAddress = useAtomValue(addressAtom)

  const locked = assetInfo?.locked
  const isBrt = token.tokenType === TokenType.BRT
  const isBtc = token.tokenType === TokenType.BTC
  const isMine = !(isBrt || isBtc) && assetInfo?.owner === userAddress

  return <div className={styles.address}>
    <p>{token.tokenType}</p>
    <div className='flex justify-between'>
      <div className='flex'>
        <div className={['flex items-center', styles.balanceWrap].join(' ')}>
          <span className='text-[rgba(255,255,255,0.7)] text-[20px]'>balance</span>
          <span className='text-[20px]'>{token.balance}</span>
        </div>
        {
          token.tokenType === TokenType.BTC && <div className={['flex items-center', styles.balanceWrap].join(' ')}>
            <span className='text-[rgba(255,255,255,0.7)] text-[20px]'>UnConfirmBalance</span>
            <span className='text-[20px]'>{token.unconfirmedBalance}</span>
          </div>
        }
      </div>
      <div className={[styles.buttonWraps, 'flex items-center justify-end gap-3'].join(' ')}>
        <Button variant={'default'} style={{ marginRight: 3 }} size={'sm'}
          type='button' onClick={() => setOpenSend(true)}>Send</Button>
        {
          !isBtc && !isBrt && <Button variant={'default'}
            type='button' size={'sm'} style={{ marginRight: 3 }} onClick={() => {
              NiceModal.show(`assetsInfoModal-${token.tokenType}-${assetInfo?.asset}`)
            }}>Token Info</Button>
        }
        {
          assetInfo && isMine &&
          <>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="size-8 p-0">
                  <span className="sr-only">Open more menu</span>
                  <MoreHorizontal className="size-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                {
                  isMine && !locked && <DropdownMenuItem
                    onClick={() => setOpenIssueAdditional(true)}
                  >
                    Issue Additional
                  </DropdownMenuItem>
                }

                {isMine && <DropdownMenuItem
                  onClick={() => setTransferOpen(true)}
                >
                  Transfer Ownership
                </DropdownMenuItem>}

                {
                  isMine && !locked && <DropdownMenuItem
                    onClick={() => setLockToken(true)}
                  >
                    Lock Token Issuance
                  </DropdownMenuItem>
                }

                {
                  isMine && <DropdownMenuItem
                    onClick={() => setChangeTokenDescription(true)}
                  >
                    Change Token Description
                  </DropdownMenuItem>
                }

              </DropdownMenuContent>
            </DropdownMenu>
            {!isBtc && !isBrt && <AssetsInfoModal id={`assetsInfoModal-${token.tokenType}-${assetInfo?.asset}`} token={token} assetInfo={assetInfo} />}
          </>
        }
      </div>
    </div>
    <div className={styles.mobileButtonWraps}>
      <Button
        className={styles.mobileButton}
        variant={'default'}
        size={'sm'}
        type='button'
        onClick={() => setOpenSend(true)}
      >
        Send
      </Button>
      {
        !isBtc && !isBrt && <Button className={styles.mobileButton} variant={'default'}
          type='button' size={'sm'} onClick={() => {
            NiceModal.show(`assetsInfoModal-${token.tokenType}-${assetInfo?.asset}`)
          }}>Token Info</Button>
      }
      {
        assetInfo && isMine &&
        <>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button className={styles.mobileButton} variant={'ghost'} style={{ border: '1px solid #637592' }}
                type='button' size={'sm'}>
                more
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {
                isMine && !locked && <DropdownMenuItem
                  onClick={() => setOpenIssueAdditional(true)}
                >
                  Issue Additional
                </DropdownMenuItem>
              }

              {isMine && <DropdownMenuItem
                onClick={() => setTransferOpen(true)}
              >
                Transfer Ownership
              </DropdownMenuItem>}

              {
                isMine && !locked && <DropdownMenuItem
                  onClick={() => setLockToken(true)}
                >
                  Lock Token Issuance
                </DropdownMenuItem>
              }

              {
                isMine && <DropdownMenuItem
                  onClick={() => setChangeTokenDescription(true)}
                >
                  Change Token Description
                </DropdownMenuItem>
              }

            </DropdownMenuContent>
          </DropdownMenu>
          {!isBtc && !isBrt && <AssetsInfoModal id={`assetsInfoModal-${token.tokenType}-${assetInfo?.asset}`} token={token} assetInfo={assetInfo} />}
        </>
      }
      {
        !!assetInfo && <>
          {openIssueAdditional && <Dialog title='Issue Additional Token Quantity' open={openIssueAdditional} onClose={() => setOpenIssueAdditional(false)} content={<IssueAdditionalDialog onClose={() => setOpenIssueAdditional(false)} token={token} assetInfo={assetInfo} tokenAssets={tokenAssets} />} />}
          {changeTokenDescription && <Dialog title='Change Token Description' open={changeTokenDescription} onClose={() => setChangeTokenDescription(false)} content={<ChangeTokenDescriptionDialog onClose={() => setChangeTokenDescription(false)} token={token} assetInfo={assetInfo} tokenAssets={tokenAssets} />} />}
          {lockToken && <Dialog title='Are you Sure?' open={lockToken} onClose={() => setLockToken(false)} content={<LockTokenDialog onClose={() => setLockToken(false)} token={token} assetInfo={assetInfo} />} />}
          {transferOpen && <Dialog title='Transfer Token' open={transferOpen} onClose={() => setTransferOpen(false)} content={<TransferDialog onClose={() => setTransferOpen(false)} token={token} assetInfo={assetInfo} tokenAssets={tokenAssets} />} />}
        </>
      }
    </div>
    {openSend && <Dialog
      open={openSend}
      title={'Send Tokens'}
      onClose={() => setOpenSend(false)}
      content={<SendDialog tokenInfo={token} onClose={() => setOpenSend(false)} />}
    />}
  </div>
}
