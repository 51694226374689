import { toast } from '@/components/ui/Toast'
import { message } from 'antd'
import axios from 'axios'

const currentUrl = window?.location?.origin && !window.location.origin.includes('localhost') ? window.location.origin : undefined

const instance = axios.create({
  baseURL: currentUrl ?? 'https://app.bitroot.co',
})

instance.interceptors.request.use((config) => {

  return config
})

instance.interceptors.response.use((response) => {
  return new Promise((resolve, reject) => {
    const { data } = response

    const { error } = data

    if (error) {
      return reject(error.data.message)
    }

    const { result } = data

    resolve(result)
  })
}, (error) => {
  message.error(error.message)

  return Promise.reject(error)
})


export default instance

