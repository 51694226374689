import { Table } from '@/components/ui/Table'
import styles from './index.module.scss'
import { useMemo } from 'react'
import { TableFooter } from '@/components/TableFooter'
import { useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { addressAtom } from '@/store'
import { getOrderMatchesApi } from '@/request/counterparty'
import { formatDecimals } from '@/utils/format-decimals'
import { cn } from '@/utils/cn'

export const OrderHistory = () => {
  const userAddress = useAtomValue(addressAtom)

  const addressList = [userAddress as string]
  const { data = [] } = useQuery({
    refetchOnMount: 'always',
    queryKey: [{ key: 'getOrderMatchesApi', addressList }],
    queryFn: async () => {
      const rs = await getOrderMatchesApi(addressList)
      return rs || []
    },
  })

  const formatedOrderMatches = useMemo(() => {
    const formatedData = data.map((item) => {
      const formatedItem: Record<string, any> = {
        block_index: item.block_index,
        status: item.status,
      }

      if (userAddress === item.tx0_address) {
        formatedItem.address_label = userAddress
        formatedItem.give_quantity = item.forward_quantity
        formatedItem.get_quantity = item.backward_quantity
        formatedItem.give_asset = formatUnit(item.forward_asset)
        formatedItem.get_asset = formatUnit(item.backward_asset)
      } else {
        formatedItem.address_label = userAddress
        formatedItem.give_quantity = item.backward_quantity
        formatedItem.get_quantity = item.forward_quantity
        formatedItem.give_asset = formatUnit(item.backward_asset)
        formatedItem.get_asset = formatUnit(item.forward_asset)
      }

      formatedItem.give_quantity_str = formatDecimals(formatedItem.give_quantity, {
        unit: formatedItem.give_asset,
        decimals: 8,
        volScale: 8,
      })

      formatedItem.get_quantity_str = formatDecimals(formatedItem.get_quantity, {
        unit: formatedItem.get_asset,
        decimals: 8,
        volScale: 8,
      })

      return formatedItem
    })

    return formatedData
  }, [data, userAddress])

  return (
    <div className={cn(styles.wrap, 'py-9 px-2.5 sm:px-9')}>
      <div className={styles.titles}>
        <p>My Order History</p>
        {/* <div className={styles.helper}>
                    <img src={help} alt="help" />
                    <span>Page Help</span>
                </div> */}
      </div>
      <div className={cn(styles.body, '!px-2.5 sm:!px-5')}>
        <div className={cn(styles.tableWrap, 'overflow-x-auto')}>
          <Table
            columns={[
              { dataIndex: 'status', label: 'status' },
              { dataIndex: 'block_index', label: 'block' },
              { dataIndex: 'address_label', label: 'address' },
              { dataIndex: 'give_quantity_str', label: 'sell Quantity' },
              { dataIndex: 'get_quantity_str', label: 'Buy Quantity' },
            ]}
            data={formatedOrderMatches}
            noData={<TableFooter />}
          />
        </div>
      </div>
    </div>
  )
}

function formatUnit(unit: string) {
  if (unit === 'XCP') {
    return 'BRT'
  }

  return unit
}
