import { TableFooter } from '@/components/TableFooter'
import { Table } from '@/components/ui/Table'
import { cn } from '@/utils/cn'
import searchIcon from 'assets/icons/search.svg'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { OrderBook } from './_comps/order-book'
import { Header } from './_comps/header'
import { OrderForm } from './_comps/order-form'
import { PriceHistory } from './_comps/price-history'
import { useRequest } from 'ahooks'
import { getMarketList, getMarketTrades, getTopPairs } from '@/request/api'
import { useAtomValue } from 'jotai'
import { addressAtom } from '@/store'
import { useState } from 'react'
import dayjs from 'dayjs'
import styles from './index.module.scss'

export const MarketDetailPage = () => {
  const [data, setData] = useState<any[]>([])
  let { pairSymbol = '' } = useParams()
  const [pairBaseSymbol, pairQuoteSymbol] = pairSymbol?.split('_')
  const [currentSymbol, setCurrentSymbol] = useState<any>({})
  const [tradeList, setTradeList] = useState<any[]>([])
  const [token1, setToken1] = useState<string>('')
  const [isOther, setIsOther] = useState<boolean>(false)
  const [token2, setToken2] = useState<string>('')
  const navigate = useNavigate()

  const userAddress = useAtomValue(addressAtom)


  useRequest(async () => {
    const rs = await getTopPairs([userAddress])

    setData(rs)
    return rs
  }, {
    ready: !!userAddress,
    refreshDeps: [userAddress],
  })

  useRequest(async () => await getMarketList(), {
    ready: !!userAddress,
    onSuccess: (res) => {

      const symbol = res.find((item: any) => item.base_asset === pairBaseSymbol) ?? {}

      setCurrentSymbol(symbol)
    }
  })

  useRequest(async () => await getMarketTrades(pairBaseSymbol, pairQuoteSymbol, [userAddress]), {
    ready: !!userAddress && !!pairBaseSymbol && !!pairQuoteSymbol,
    refreshDeps: [pairBaseSymbol, pairQuoteSymbol, userAddress],
    onSuccess: (res: any) => {
      setTradeList(() => res ?? [])
    }
  })

  return (
    <>
      <div className={styles.mobileContainer}>
        <Header rate={currentSymbol?.progression as string ?? '0.00'} />
        <p className={styles.mobileContainer_desc}>
          Warning and Disclaimer: Every asset can be viewed on the "Markets" page. The hosts of Bitroot wallet.io do not filter very likely scam or fraudulent assets, and explicitly disclaim any
          responsibility therefor. Caveat emptor: do your own diligence, and do not invest more than you can afford to lose.
        </p>
        <div className={styles.topPairsWrap}>
          <p className={styles.topPairs_subject}>Top pairs</p>
          <div className={styles.topPairs}>
            {data?.map?.((item: any, index: number) => {
              return (
                <NavLink className={styles.pairItem} to={`/market/${item.base_asset === 'XCP' ? 'BRT' : item.base_asset}_${item.quote_asset === 'XCP' ? 'BRT' : item.quote_asset}`}>
                  <div
                    key={index}
                  >
                    {item.base_asset === 'XCP' ? "BRT" : item.base_asset} / {item.quote_asset === 'XCP' ? "BRT" : item.quote_asset}
                  </div>
                </NavLink>
              )
            })}
          </div>
        </div>
        {/* <div className={styles.topPairsWrap}>
          <p className={styles.topPairs_subject}>Select Another pairs</p>
          <div className={styles.selectPairs}>
            <div className={styles.selectPairs_item}>
              <p className={styles.selectPairs_item_title}>Token1</p>
              <input className={styles.token1Input}
                placeholder='Assets1 Name'
                value={token1}
                onChange={(e) => setToken1(e.target.value)} />
            </div>
            <div className={styles.selectPairs_item}>
              <p className={styles.selectPairs_item_title}>Token2</p>
              <div className={styles.selectPairs_item_wrap}>
                <div className={styles.selectPairs_item_wrap_token} style={{ color: !isOther && token2 === 'BRT' ? '#009046' : 'white' }} onClick={() => {
                  setToken2('BRT')
                  setIsOther(false)
                }}>BRT</div>
                <div className={styles.selectPairs_item_wrap_token} style={{ color: isOther ? '#009046' : 'white' }} onClick={() => setIsOther(!isOther)}>Other</div>
              </div>
              {
                isOther &&
                <div className={styles.customWrap}>
                  <input
                    className={styles.customInput}
                    placeholder='Assets2 Name'
                    onChange={(e) => {
                      setToken2(e.target.value)
                    }}
                  />
                  <button
                    className={styles.detailButton}
                    onClick={() => {
                      if (token1 && token2) {
                        navigate(`/market/${token1}_${token2}`)
                      }
                    }}
                  >
                    Detail
                  </button>
                </div>
              }
            </div>
          </div>
        </div> */}
        <PriceHistory />
        <OrderForm />
        <OrderBook />
      </div>
      <div className={[styles.container, 'max-w-[980px] mx-auto p-[38px] min-h-full text-white'].join(' ')}>
        <Header rate={currentSymbol?.progression as string ?? '0.00'} />
        <div className="mt-4 text-[12px] leading-5 text-[#929292]">
          Warning and Disclaimer: Every asset can be viewed on the "Markets" page. The hosts of Bitroot wallet.io do not filter very likely scam or fraudulent assets, and explicitly disclaim any
          responsibility therefor. Caveat emptor: do your own diligence, and do not invest more than you can afford to lose.
        </div>

        <div className="mt-[30px] flex gap-[25px]">
          <div className="flex-1 rounded-[15px] bg-[#101417] p-5">
            <div className="font-medium text-[18px] leading-[22px]">Top pairs</div>

            <div className={cn('grid grid-cols-4 mt-3 bg-black rounded-[5px] min-w-[480px]')}>
              {data?.map((item, index) => {
                return (
                  <NavLink to={`/market/${item.base_asset === 'XCP' ? 'BRT' : item.base_asset}_${item.quote_asset === 'XCP' ? 'BRT' : item.quote_asset}`}>
                    <div
                      key={index}
                      className={cn('hover:text-[#00ff8e] text-small cursor-pointer border-[#101417] py-2 px-2.5 text-center whitespace-nowrap', {
                        'border-r': true,
                        'border-b': true,
                      })}
                    >
                      {item.base_asset === 'XCP' ? 'BRT' : item.base_asset}_{item.quote_asset === 'XCP' ? 'BRT' : item.quote_asset}
                    </div>
                  </NavLink>
                )
              })}
            </div>
          </div>

          {/* <div className="flex-1 flex flex-col rounded-[15px] bg-[#101417] p-5 w-[200px]">
            <div className="font-medium text-[18px] leading-[22px]">Select Another pair</div>
            <div className="flex flex-1 gap-5 py-2.5 px-4 mt-3 bg-black rounded-[5px]">
              <div className="flex-1">
                <div className="font-bold text-[12px] leading-[16px]">Token1</div>
                <input className="mt-4 border border-[#101417] text-[12px] w-[100px] rounded-[5px] py-2 px-2 bg-[transparent] outline-none"
                  placeholder='Assets1 Name'
                  value={token1}
                  onChange={(e) => setToken1(e.target.value)} />
              </div>
              <div className="flex-1 min-w-[180px] min-h-[100px]" >
                <div className="font-bold text-[12px] leading-[16px]">Token2</div>
                <div className="flex mt-4 border border-[#101417] rounded-[5px] ">
                  <div className="flex-1 text-center py-2 px-2 text-[12px]  border-r border-[#101417] cursor-pointer" style={{ color: !isOther && token2 === 'BRT' ? '#009046' : 'white' }} onClick={() => {
                    setToken2('BRT')
                    setIsOther(false)
                  }}>BRT</div>
                  <div className="flex-1 text-center py-2 px-2 text-[12px]" style={{ color: isOther ? '#009046' : 'white' }} onClick={() => setIsOther(!isOther)}>Other</div>
                </div>
                <div className='flex items-center	gap-4 justify-end mt-[10px] pr-[10px]'>
                  {
                    isOther && <input
                      className="mt-4 border border-[#101417] text-[12px] w-[100px] rounded-[5px] py-2 px-2 bg-[transparent] outline-none"
                      placeholder='Assets2 Name'
                      onChange={(e) => {
                        setToken2(e.target.value)
                      }}
                    />
                  }
                  <button
                    className='border-[#101417] bg-white text-[12px]'
                    style={{ color: 'black', padding: '0 10px', height: '36px', borderRadius: '6px', marginTop: '15px', fontSize: '12px' }}
                    onClick={() => {
                      if (token1 && token2) {
                        navigate(`/market/${token1}_${token2}`)
                      }
                    }}
                  >
                    Detail
                  </button>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <div>
          <PriceHistory />
        </div>
        <div className="mt-5">
          <OrderForm />
        </div>

        <div className="mt-5">
          <OrderBook />
        </div>

        <div className="min-h-[352px] flex-1 flex flex-col mt-[20px] rounded-[15px] bg-[#101417] p-5">
          <div className="flex justify-between">
            <div className="font-medium text-[18px] leading-[22px]">Completed Trades for {(pairBaseSymbol)}/{pairQuoteSymbol}</div>

            <div className="min-w-[200px] items-center gap-3 flex py-2.5 px-4 bg-black rounded-[5px] border border-[#101417]">
              <div>
                <img src={searchIcon} className="size-[12px]" alt="" />
              </div>

              <input className="flex-1 bg-transparent outline-none text-[12px] leading-[17px] font-medium text-[#929292]" />
            </div>
          </div>
          <div className="flex-1 bg-black rounded-[5px] mt-[18px]">
            <Table
              columns={[
                { dataIndex: 'Type', label: 'Type', render: (row) => <span style={{ fontSize: '12px', color: row.type === 'BUY' ? 'rgba(0, 144, 70, 1)' : 'rgba(212, 87, 86, 1)', padding: '20px' }}>{row.type}</span> },
                { dataIndex: 'BlockTime', label: 'Block Time', render: (row) => <span style={{ fontSize: '12px', padding: '20px' }}>{dayjs(row.block_time * 1000 ?? '0' as string).format()}</span> },
                { dataIndex: 'PriceBRT', label: 'Price', render: (row) => <span style={{ fontSize: '12px', padding: '20px' }}>{row.price}</span> },
                { dataIndex: 'AmountBRT', label: 'Amount', render: (row) => <span style={{ fontSize: '12px', padding: '20px' }}>{row.amount / 10e7}</span> },
                { dataIndex: 'TotalBRT', label: 'Total', render: (row) => <span style={{ fontSize: '12px', padding: '20px' }}>{row.total / 10e7}</span> },
                { dataIndex: 'status', label: 'Status', render: (row) => <span style={{ fontSize: '12px', padding: '20px' }}>{row.status}</span> },
              ]}
              data={tradeList}
              noData={<TableFooter />}
            />
          </div>
        </div>
      </div>
    </>
  )
}
