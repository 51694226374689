import { addressAtom } from "@/store"
import { Button, Checkbox, Form, Input, InputNumber, message, Radio, Select, Slider } from "antd"
import { useAtomValue } from "jotai"
import { useState } from "react"
import { CreateTokenParamsType, createTokenAction } from "@/request/api"
import { useRequest } from "ahooks"
import { broadcastTxByUnisate, getAddressPubKey, getBitcoinFees, getUtxoInfo, getUtxos, hexToPSBT, hexToPSBTForUtxos, randomName, signMessageByUnisat } from "@/utils"
import BigNumber from "bignumber.js"

export enum TokenType {
  BTC = 'BTC',
  BRT = 'BRT',
}

export const CreateTokenDialog = ({ onClose, currentAddressNormalBanlance }: { onClose: () => void, currentAddressNormalBanlance: any[] }) => {
  const [form] = Form.useForm()
  const userAddress = useAtomValue(addressAtom)
  const [transFee, setTransFee] = useState<number | null>()
  const [satoshisFee, setSatoshisFee] = useState<number>(0)
  const [nameType, setNameType] = useState<string>('0')
  const [fee, setFee] = useState<number>(0)

  const { data: feeOptions } = useRequest(async () => {
    const res = await getBitcoinFees()
    if (!res) return {}
    const keys = Object.keys(res).map((item) => Number(item)).filter((key, index) => index < 10)

    const _feeOptions = keys.reduce((prev, item) => {
      prev[item] = {
        fee: new BigNumber(res[item]).multipliedBy(1000).toFixed(2),
        baseFee: res[item].toFixed(2)
      }

      return prev
    }, {} as any)

    return _feeOptions
  }, {
    ready: true,
    pollingInterval: 10000
  })

  const { run, loading } = useRequest(async (params: CreateTokenParamsType) => await createTokenAction(params), {
    manual: true,
    debounceWait: 1000,
    onSuccess: async (res: any) => {
      setTransFee(res?.btc_fee)

      try {
        const utxos = await getUtxos(userAddress)
        const psbtHex = hexToPSBTForUtxos(res?.tx_hex, utxos) as string
        const signedPsbt = await signMessageByUnisat(psbtHex) as string
        await broadcastTxByUnisate(signedPsbt)

        message.success('Transaction Submitted! Please kindly wait for the block confirmation!')
        onClose?.()
      } catch (e: any) {
        message.error(e.message || e)
      }
    },
    onError: (e: any) => {
      message.error(e.message || e)
    }
  })


  return <div className="bg-[#111318]">
    <Form
      form={form}
      className="mt-[10px]"
      layout="vertical"
      initialValues={{
        feeRate: 10,
        isDivisible: true,
        nameType: '0',
      }}
      onValuesChange={(val, allVal) => {
        if (allVal.feeRate) {
          setSatoshisFee(feeOptions[11 - allVal?.feeRate].baseFee)
        }

        if (val.nameType) {
          setNameType(val.nameType)

          form.setFieldValue('tokenName', allVal.nameType === '2' ? randomName() : '')
        }
      }}
      onFinish={async (value) => {
        const { feeRate, nameType, parentAssets, tokenName, desc, quantity, isDivisible } = value
        const fee = Number(feeOptions[feeRate].fee)

        setFee(fee)
        const pubkey = await getAddressPubKey(userAddress)

        const params: CreateTokenParamsType = {
          asset: `${nameType === '1' ? `${parentAssets}.` : ''}${tokenName}`,
          description: desc,
          quantity: quantity * 100000000,
          source: userAddress,
          divisible: isDivisible,
          pubkey: pubkey as string,
          fee_per_kb: fee,
        }

        run(params)

      }}>
      <Form.Item label={'Address'} rules={[{ required: true, }]}>
        <div>{userAddress}</div>
      </Form.Item>
      <Form.Item label={'Name Type'} name={'nameType'} rules={[{ required: true, }]}>
        <Radio.Group className="flex flex-col	">
          <Radio className="mt-[5px]" value={'0'}>Alphabetic name with anti-spam fee (0.5 BRT)</Radio>
          <Radio className="mt-[5px]" value={'1'}>Subasset with anti-spam fee (0.25 BRT)</Radio>
          <Radio className="mt-[5px]" value={'2'}>Free numeric name</Radio>
        </Radio.Group>
      </Form.Item>
      {
        nameType === '1' && <Form.Item rules={[{ required: true, }]} label={'Parent Assets'} name='parentAssets'>
          <Select options={currentAddressNormalBanlance.filter((asset) => !['XCP', 'BRT'].includes(asset.asset)).map((asset) => ({ label: asset.asset, value: asset.asset }))} />
        </Form.Item>
      }
      <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.nameType !== curValues.nameType}>
        {() => <Form.Item label={'Token Name'} name={'tokenName'} rules={[{
          required: true,
          validator: ((_, value) => {
            const nameType = form.getFieldValue('nameType')

            if (nameType === '0') {
              const regex = /^(?!A)[A-Z]{4,12}$/;
              const rs = regex.test(value)

              if (!rs) {
                return Promise.reject(`Must contain between 4 and 12 uppercase letters only (A-Z), and cannot start with 'A'.`)
              }
            }

            if (nameType === '2') {
              const regex = /^A\d{5,}$/

              const rs = regex.test(value)

              if (!rs) {
                return Promise.reject(`Must start with 'A', followed by a large random number.`)
              }
            }

            return Promise.resolve()
          })
        }]}>
          <Input />
        </Form.Item>}
      </Form.Item>
      <Form.Item label={'Description'} name={'desc'}>
        <Input />
      </Form.Item>
      <Form.Item label={'Quantity'} name={'quantity'} rules={[{ required: true, }]}>
        <InputNumber className="w-full" step={1} />
      </Form.Item>
      <Form.Item layout={'horizontal'} label={'Make divisible'} name={'isDivisible'} rules={[{ required: true, }]} valuePropName={'checked'}>
        <Checkbox />
      </Form.Item>
      <Form.Item layout="horizontal" label={'BitCoin Fee'} name={'feeRate'}>
        <Slider max={10} min={1} step={1} className="w-[250px]" />
      </Form.Item>
      <Form.Item dependencies={['feeRate']}>
        {
          ({ getFieldValue }) => {
            const feeRate = getFieldValue('feeRate')

            return (
              <div>
                {
                  transFee && <p className={'text-[#4A90E2] text-[14px]'}>
                    Transaction fee: {transFee / 100000000} BTC
                  </p>
                }
                {!!fee && <p className={'text-[#fff] text-[14px]'}>
                  Estimated wait: between {feeRate === 10 ? `1` : `1 ~ ${11 - feeRate}`} blocks ({satoshisFee}  Satoshis/Byte)
                </p>}
              </div>
            )
          }
        }

      </Form.Item>
      <Form.Item>
        <div className={'flex justify-end'}>
          <Button color="default" onClick={() => onClose()}>Cancel</Button>
          <Button
            className="ml-[10px]"
            loading={loading}
            type="primary"
            onClick={() => form.submit()}
          >
            OK
          </Button>
        </div>
      </Form.Item>
    </Form>
  </div>
}