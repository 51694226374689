import { Dialog } from "../ui/Dialog"
import styles from './index.module.scss'
import { useState } from "react"
import Radio from "../ui/Radio"
import { addressAtom } from "@/store"
import { useAtomValue } from "jotai"
import { message, Button } from "antd"


export const SignMessageDialog = ({ open, onClose }: { open: boolean, onClose: () => void, }) => {
    const [value, setValue] = useState<string>('')
    const [encodeType, setEnCodeType] = useState<string>('0')
    const [signedMessage, setSignMessage] = useState<string>('')
    const userAddress = useAtomValue(addressAtom)

    return <Dialog
        open={open}
        title={'Sign a Message'}
        onClose={() => onClose()}
        titleClass={styles.title}
        containerClass={styles.wrap}
    >
        <>
            <div className={styles.containerMobile}>
                <p className={styles.descMobile}>
                    Sign a message from address {userAddress}.
                </p>
                <div className={styles.signWrapMobile}>
                    <p className={styles.signWrapMobile_label}>Message to Sign</p>
                    <div className={styles.textareaWrap}>
                        <textarea className={styles.textarea} value={value} onChange={(e) => setValue(e.target.value)} />
                    </div>
                </div>
                <div className={styles.signWrapMobile}>
                    <p className={styles.signWrapMobile_label}>Message to Sign</p>
                    <div className={styles.radioWrap}>
                        <Radio
                            value={encodeType}
                            items={[
                                { label: 'Base 64 Encoded (Bitcoin Core Compatible)', value: '0' },
                                { label: 'Hex Encoded', value: '1' }
                            ]}
                            onChange={(e: string) => setEnCodeType(e)}
                        />
                    </div>
                </div>
                <div className={styles.signWrapMobile}>
                    <p className={styles.signWrapMobile_label}>Signed Message</p>
                    <div className={styles.textareaWrap}>
                        <textarea className={styles.textarea} value={signedMessage} />
                    </div>
                </div>
            </div>

            <div className={styles.container}>
                <p className={styles.desc}>
                    Sign a message from address {userAddress}.
                </p>
                <div className={styles.signWrap}>
                    <p className={styles.signWrap_label}>Message to Sign</p>
                    <div className={styles.textareaWrap}>
                        <textarea className={styles.textarea} value={value} onChange={(e) => setValue(e.target.value)} />
                    </div>
                </div>
                <p className={styles.signDesc}>
                    Enter the message text to be signed.
                </p>
                <div className={styles.signWrap}>
                    <p className={styles.signWrap_label}>Message to Sign</p>
                    <div className={styles.radioWrap}>
                        <Radio
                            value={encodeType}
                            items={[
                                { label: 'Base 64 Encoded (Bitcoin Core Compatible)', value: '0' },
                                { label: 'Hex Encoded', value: '1' }
                            ]}
                            onChange={(e: string) => setEnCodeType(e)}
                        />
                    </div>
                </div>
                <div className={styles.signWrap}>
                    <p className={styles.signWrap_label}>Signed Message</p>
                    <div className={styles.textareaWrap}>
                        <textarea className={styles.textarea} value={signedMessage} />
                    </div>
                </div>
                <p className={styles.signDesc}>
                    The signed message appears above.
                </p>
            </div>
            <div className={'flex justify-end py-[16px] px-[32px]'}>
                <Button color="default" onClick={() => onClose()}>Close</Button>
                <Button
                    className="ml-[10px]"
                    type="primary"
                    onClick={async () => {
                        const messageHash = Buffer.from(value, 'utf8');
                        try {
                            const signedMessageBase64 = await window?.unisat.signMessage(messageHash.toString('utf8'));
                            const signedMessageHex = Buffer.from(signedMessageBase64, 'base64').toString('hex');

                            setSignMessage(encodeType === '0' ? signedMessageBase64 : signedMessageHex)
                        } catch (e: any) {
                            message.error(e?.message)
                        }
                    }}
                >
                    Sign
                </Button>
            </div>
        </>
    </Dialog>
}