import { Slot, Slottable } from '@radix-ui/react-slot'
import * as React from 'react'
import { cva } from 'class-variance-authority'
import type { VariantProps } from 'class-variance-authority'


import { Icons } from '../icons'
import { cn } from '@/utils/cn'

const buttonVariants = cva(
  cn('inline-flex gap-2.5 items-center justify-center whitespace-nowrap text-[12px] leading-normal font-normal ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50'),
  {
    variants: {
      variant: {
        default: cn('bg-primary text-primary-foreground hover:bg-primary/80'),
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary: 'bg-white text-[#080A0C] hover:bg-white/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'relative after:absolute after:bg-primary after:bottom-2 after:h-[1px] after:w-2/3 after:origin-bottom-right after:scale-x-0 hover:after:origin-bottom-left hover:after:scale-x-100 after:transition-transform after:ease-in-out after:duration-300',
      },
      size: {
        default: cn('px-10 py-2 rounded-lg'),
        sm: 'px-4 py-2 rounded-md ',
        // lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
        submit: 'py-4 text-lg leading-[30px]',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

interface IconProps {
  LeftIcon?: React.ReactNode
  RightIcon?: React.ReactNode
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  loading?: boolean
  block?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps & IconProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      loading = false,
      block = false,
      children,
      LeftIcon,
      RightIcon,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(
          buttonVariants({
            variant,
            size,
            className: cn(
              {
                'flex w-full flex-1': block,
              },
              className,
            ),
          }),
        )}
        ref={ref}
        {...props}
        disabled={props.disabled || loading}
      >
        {(LeftIcon || loading) && (
          <div>{LeftIcon ? LeftIcon : loading ? <Icons.Spinner className="size-5 animate-spin" /> : null}</div>
        )}
        <Slottable>{children}</Slottable>
        {RightIcon && <div>{RightIcon}</div>}
      </Comp>
    )
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
