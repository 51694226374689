import { BitcoinFeeBlockLevel, getFeeInfoByLevel } from '@/components/form/bitcoin-fee'
import { rpcRequest } from '@/request/api'
import { addressAtom } from '@/store'
import { getAddressPubKey } from '@/utils'
import { useMutation } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'

export type CreateCancelOrderParams = {
  offer_hash: string
  source: string
  fee: number
}

export const useCreateCancelOrder = () => {

  const userAddress = useAtomValue(addressAtom)

  const {
    mutateAsync: createCancelMutateAsync,
    mutate: createCancelMutate,
    ...rest
  } = useMutation({
    mutationKey: [{ key: 'proxy_to_counterpartyd', methods: 'create_cancel' }],
    mutationFn: async (params: CreateCancelOrderParams) => {
      const pubkey = await getAddressPubKey(userAddress)
      const rs = await rpcRequest('proxy_to_counterpartyd', {
        method: 'create_cancel',
        params: {
          ...params,
          encoding: 'auto',
          pubkey,
          allow_unconfirmed_inputs: true,
          fee_per_kb: params.fee,
        },
      })

      const data = rs as unknown as string

      return data
    },
  })

  return { createCancelMutate, createCancelMutateAsync, ...rest }
}
