import React from 'react';
import * as DropdownMenuArk from '@radix-ui/react-dropdown-menu';
import styles from './index.module.scss'

export type DropMenuItemType = {
  label: string | number | React.ReactElement,
  hasSeparator?: boolean,
  children?: DropMenuItemType[],
  key: string,
  onClick?: () => void
}

export const DropdownMenu = ({ tirggerEl, items, sideOffset }: { sideOffset?: number, tirggerEl: React.ReactElement, items: any[] }) => {

  return (
    <DropdownMenuArk.Root>
      <DropdownMenuArk.Trigger asChild className={styles.trigger}>
        {tirggerEl}
      </DropdownMenuArk.Trigger>
      <DropdownMenuArk.Portal>
        <DropdownMenuArk.Content className={styles.dropContent} sideOffset={sideOffset ?? 5}>
          {
            items.map((item: DropMenuItemType, key: number) => {
              if (item.children?.length) {
                return (
                  <DropdownMenuArk.Sub key={key}>
                    <DropdownMenuArk.SubTrigger className={styles.trigger}>
                      <div className={styles.triggerLabel}>
                        {item.label}
                      </div>
                    </DropdownMenuArk.SubTrigger>
                    <DropdownMenuArk.Portal>
                      <DropdownMenuArk.SubContent
                        className={styles.dropContent}
                        sideOffset={2}
                        alignOffset={-5}
                      >
                        {
                          item.children?.map((childItem: DropMenuItemType) => {
                            return <DropdownMenuArk.Item className="DropdownMenuArkItem" key={childItem.key} onClick={() => childItem?.onClick?.()}>
                              <div className={styles.dropItem}>{childItem.label}</div>
                            </DropdownMenuArk.Item>
                          })
                        }
                      </DropdownMenuArk.SubContent>
                    </DropdownMenuArk.Portal>
                  </DropdownMenuArk.Sub>
                )
              }
              return (
                <DropdownMenuArk.Item key={item.key} className="DropdownMenuArkItem" onClick={() => item?.onClick?.()}>
                  <div className={styles.dropItem}>
                    {item.label}
                  </div>
                </DropdownMenuArk.Item>
              )
            })
          }
        </DropdownMenuArk.Content>
      </DropdownMenuArk.Portal>
    </DropdownMenuArk.Root>
  )
}