import styles from './index.module.scss'
import { Box, Button } from '@radix-ui/themes'
import water from 'assets/images/home/water.png'
import { useNavigate } from 'react-router-dom'
import { connectWallet } from '@/utils'

export const Home = () => {
    const navigate = useNavigate()
    return (
        <div className={styles.home} style={{ backgroundImage: `url(${water})` }}>
            <p className={styles.title}>Welcome to Bitroot</p>
            <p className={styles.subTitle}>Powered by Bitroot</p>
            <div className={styles.container_wrap}>
                <div className={styles.container_body}>
                    <div className={styles.buttonWrap}>
                        <Button
                            className={styles.openWallet}
                            onClick={async () => {
                                try {
                                    await connectWallet()
                                    navigate('/balance')
                                } catch (e) {
                                    console.log(e)
                                }
                            }}
                        >
                            <Box className={styles.openWalletText}>
                                Open Wallet
                            </Box>
                        </Button>
                    </div>
                    <div className={styles.openInfo}>
                        <span className={styles.openInfo_text}>Log into your wallet</span>
                        <span className={styles.openInfo_tag}>Testnet In Use</span>
                    </div>
                </div>

            </div>
        </div>
    )
}