import BigNumber from 'bignumber.js'
import { isNil, merge } from 'lodash-es'
import numbro from 'numbro'
import type { BNumberValue } from './b-number'

import { BNumber } from './b-number'

const FORMAT_AVERAGE_VOL_SCALE = 2
const FORMAT_VALUE_FALLBACK = '??'
const COMMON_PERCENT_VOL_SCALE = 2

export type FormatDecimalsOpt = {
  decimals?: number
  volScale?: number
  unit?: string
  unitSeparated?: string
  isFrontUnit?: boolean
  defaultLabel?: string
  prefix?: string
  fallbackToZero?: boolean
  autoHideDecimal?: boolean
  positive?: boolean
  sign?: string
  forceSign?: boolean
  minimumFallback?: boolean
  format?: Omit<numbro.Format, 'mantissa' | 'forceSign'>
}

const enUs: numbro.NumbroLanguage = merge(numbro.languageData('en-US'), {
  abbreviations: {
    thousand: 'K',
    million: 'M',
    billion: 'B',
    trillion: 'T',
  },
} as numbro.NumbroLanguage)
numbro.registerLanguage(enUs)

export function formatDecimals(amount?: BNumberValue, opt?: FormatDecimalsOpt) {
  let { volScale = 0 } = opt || {}

  const {
    decimals = 0,
    defaultLabel = FORMAT_VALUE_FALLBACK,
    fallbackToZero = false,
    autoHideDecimal = false,
    prefix,
    positive = true,
    isFrontUnit = false,
    unit,
    format,
    sign,
    forceSign = false,
    minimumFallback = false,
  } = opt || {}

  const {unitSeparated = isFrontUnit ? '' : ' ',} = opt || {}

  const initFormat: numbro.Format = {
    roundingFunction: (...arg) => {
      return Math.floor(...arg)
    },
    thousandSeparated: true,
    average: false,
    trimMantissa: true,
  }

  if (amount instanceof BNumber || amount instanceof BigNumber) {
    amount = amount.toFixed()
  }

  if (isNil(amount) || amount.toString().length === 0 || isNil(volScale)) {
    if (!fallbackToZero) {
      return defaultLabel
    }

    amount = BNumber.from(0)
  }

  let amountWithFormated = BNumber.from(amount).div(10 ** decimals)

  let isNegative = amountWithFormated.lt(0)
  if (positive && isNegative) {
    amountWithFormated = BNumber.from(0)
    isNegative = false
  }

  if (autoHideDecimal) {
    volScale = formatAutoHideDecimal(amount, {
      decimals,
      volScale,
    })
  } else if (format?.average ?? initFormat.average) {
    volScale = averageAutoHideDecimal(amount, {
      decimals,
      volScale,
    })
  }

  const numSign = isNegative ? '-' : '+'
  const frontUnitLabel = unit ? `${unit}${unitSeparated}` : ''
  const rearUnitLabel = unit ? `${unitSeparated}${unit}` : ''
  const signOfDisplayed = sign ? sign : forceSign || isNegative ? numSign : ''

  /** Minimum amount fallback */
  if (minimumFallback && !amountWithFormated.eq(0)) {
    const amountWithLimitDecimals = BNumber.from(
      numbro(amountWithFormated.toFixed()).format({
        ...initFormat,
        ...format,
        mantissa: volScale,
      }),
    )
    if (amountWithLimitDecimals.eq(0)) {
      return `<${isNegative ? '-' : ''}${1 / 10 ** volScale}${!isFrontUnit ? rearUnitLabel : ''}`
    }
  }

  let formatedValue

  try {
    formatedValue = numbro(amountWithFormated.abs().toFixed()).format({
      ...initFormat,
      ...format,
      mantissa: volScale,
    })
  } catch (err) {
    console.log(err)
    // eslint-disable-next-line no-debugger
    debugger
  }

  return `${prefix ? prefix : ''}${signOfDisplayed}${isFrontUnit ? frontUnitLabel : ''}${formatedValue}${
    !isFrontUnit ? rearUnitLabel : ''
  }`
}

type formatAutoHideDecimalParams = {
  decimals?: number
  volScale: number
}

const formatAutoHideDecimal = (amount: BNumberValue, opt: formatAutoHideDecimalParams) => {
  let { volScale } = opt
  const { decimals = 0 } = opt

  const amountWithFormated = BNumber.from(amount).div(10 ** decimals)

  if (amountWithFormated.gt(10 ** 5)) {
    volScale -= 2
  }

  if (amountWithFormated.gt(10 ** 7)) {
    volScale -= 2
  }

  return volScale
}

type averageAutoHideDecimalParams = {
  decimals?: number
  volScale: number
}

const averageAutoHideDecimal = (amount: BNumberValue, opt: averageAutoHideDecimalParams) => {
  let { volScale } = opt
  const { decimals = 0 } = opt

  const amountWithFormated = BNumber.from(amount).div(10 ** decimals)

  if (amountWithFormated.gt(1000)) {
    volScale = FORMAT_AVERAGE_VOL_SCALE
  }

  return volScale
}

export type FormatDecimalsPercent = Prettify<
  FormatDecimalsOpt & {
    isRaw?: boolean
    isUnit?: boolean
  }
>

export function formatDecimalsPercent(percent?: BNumberValue, opt: FormatDecimalsPercent = {}): string {
  const { isRaw = true, isUnit = true, positive = false, ...rest } = opt

  if (isRaw) {
    percent = BNumber.from(percent)?.multipliedBy(100).toFixed()
  }

  const formatedPercent = formatDecimals(percent, {
    unit: isUnit ? '%' : '',
    unitSeparated: '',
    positive,
    volScale: rest.volScale ?? COMMON_PERCENT_VOL_SCALE,
    ...rest,
    format: {
      ...rest.format,
      average: rest.format?.average ?? false,
    },
  })
  return formatedPercent
}

