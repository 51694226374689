import { create, useModal } from '@ebay/nice-modal-react'
import { Dialog, DialogContent, DialogFooter, DialogHeader } from '../ui-shadcn/dialog'
import { Button } from '../ui-shadcn/button'
import { useState } from 'react'
import { cn } from '@/utils/cn'

type Props = {
  message: React.ReactNode
  title: React.ReactNode
  cancel?:
    | {
        className?: string
        label?: React.ReactNode
        cb?: () => {}
      }
    | false
  confirm?: {
    className?: string
    label?: React.ReactNode
    cb?: () => {}
  }
}

export const SecondaryConfirmationDialog = create((props: Props) => {
  const modal = useModal()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [cancelLoading, setCancelLoading] = useState(false)

  return (
    <Dialog open={modal.visible} onOpenChange={modal.hide}>
      <DialogContent aria-describedby={undefined} onInteractOutside={(event) => event.preventDefault()} className="flex flex-col gap-5  w-[500px]">
        <DialogHeader className="text-white text-2xl">{props.title}</DialogHeader>

        <div className="text-white flex-1"> {props.message}</div>
        <DialogFooter className="flex gap-3">
          {props.confirm && (
            <>
              <Button
                loading={confirmLoading}
                variant={'default'}
                className={cn('', props.confirm?.className)}
                onClick={async () => {
                  setConfirmLoading(true)

                  try {
                    await Promise.resolve(props.confirm?.cb?.())
                    modal.hide()
                  } finally {
                    setConfirmLoading(false)
                  }
                }}
              >
                {props.confirm?.label ?? 'Confirm'}
              </Button>
            </>
          )}

          {props.cancel !== false ? (
            <>
              <Button
                variant={'outline'}
                loading={cancelLoading}
                className={cn('text-white', props.cancel?.className)}
                onClick={async () => {
                  if (props.cancel === false) {
                    return
                  }

                  setCancelLoading(true)

                  try {
                    await Promise.resolve(props.cancel?.cb?.())
                    modal.hide()
                  } finally {
                    setCancelLoading(false)
                  }
                }}
              >
                {props.cancel?.label ?? 'Cancel'}
              </Button>
            </>
          ) : null}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
})
