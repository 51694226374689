import { BigNumber } from 'bignumber.js'
import { isNil, isUndefined } from 'lodash-es'
import type { FormatDecimalsOpt, FormatDecimalsPercent } from './format-decimals'

import { formatDecimals, formatDecimalsPercent } from './format-decimals'

const BigNumberConfig: BigNumber.Config = {
  ROUNDING_MODE: BigNumber.ROUND_DOWN,
  EXPONENTIAL_AT: [-30, 40],
}

BigNumber.config(BigNumberConfig)

export type BNumberValue = string | number | BNumber | BigNumber | bigint

export type BNumberRoundingMode = BigNumber.RoundingMode

const BigNumberBase = BigNumber.clone(BigNumberConfig)

export class BNumber {
  static MAX_PERCENT = 100
  static MIN_PERCENT = 0

  readonly value: BigNumber

  constructor(n: BNumberValue | bigint, base?: number) {
    n = n === '' ? 0 : n
    n = n.toString()

    this.value = new BigNumberBase(n, base)
  }

  static from(value: BNumberValue): BNumber
  static from(value: Nilable<BNumberValue>): BNumber | undefined
  static from(value: Nilable<BNumberValue>): BNumber | undefined {
    if (isNil(value)) return

    if (value instanceof BNumber) {
      return value
    }

    if (value.toString().length === 0) {
      value = 0
    }

    return new BNumber(value)
  }

  static fromPercent(n: BNumberValue) {
    let value = new BNumber(n)

    value = value.lt(BNumber.MIN_PERCENT) ? new BNumber(BNumber.MIN_PERCENT) : value
    value = value.gt(BNumber.MAX_PERCENT) ? new BNumber(BNumber.MAX_PERCENT) : value

    return value
  }

  static max(...n: BNumberValue[]) {
    return BNumber.from(BigNumber.max(...n.map((i) => BNumber.from(i).toFixed())))
  }

  static min(...n: BNumberValue[]) {
    return BNumber.from(BigNumber.min(...n.map((i) => BNumber.from(i).toFixed())))
  }

  static parseUnits(value: BNumberValue, unitName: BNumberValue): BNumber
  static parseUnits(value?: BNumberValue, unitName?: BNumberValue): BNumber | undefined
  static parseUnits(value?: BNumberValue, unitName?: BNumberValue) {
    if (isNil(value) || isNil(unitName)) return

    if (value instanceof BNumber) {
      return value
    }

    if (value.toString().length === 0) {
      value = 0
    }

    return BNumber.from(value)
      .multipliedBy(10 ** BNumber.from(unitName).toNumber())
      .integerValue()
  }

  static formatUnits(value: BNumberValue, unitName?: BNumberValue): BNumber
  static formatUnits(value?: BNumberValue, unitName?: BNumberValue): BNumber | undefined
  static formatUnits(value?: BNumberValue, unitName: BNumberValue = 0) {
    if (isNil(value)) return

    if (value instanceof BNumber) {
      return value
    }

    if (value.toString().length === 0) {
      value = 0
    }

    return BNumber.from(value).div(10 ** BNumber.from(unitName).toNumber())
  }

  formatDecimals(option?: FormatDecimalsOpt) {
    return formatDecimals(this, option)
  }

  formatDecimalsPercent(option?: FormatDecimalsPercent) {
    return formatDecimalsPercent(this, option)
  }

  toPercent() {
    return this.value.multipliedBy(100)
  }

  multipliedBy(n: BNumberValue, base?: number): BNumber
  multipliedBy(n?: BNumberValue, base?: number): BNumber | undefined
  multipliedBy(n?: BNumberValue, base?: number) {
    if (isNil(n)) return

    return BNumber.from(this.value.multipliedBy(BNumber.from(n).toFixed(), base))
  }

  div(n: BNumberValue, base?: number): BNumber
  div(n?: BNumberValue, base?: number): BNumber | undefined
  div(n?: BNumberValue, base?: number) {
    if (isNil(n)) return

    return BNumber.from(this.value.div(BNumber.from(n).toFixed(), base))
  }

  times(n: BNumberValue, base?: number): BNumber
  times(n?: BNumberValue, base?: number): BNumber | undefined
  times(n?: BNumberValue, base?: number) {
    if (isNil(n)) return

    return BNumber.from(this.value.times(BNumber.from(n).toFixed(), base))
  }

  gt(n: BNumberValue, base?: number): boolean
  gt(n?: BNumberValue, base?: number): boolean | undefined
  gt(n?: BNumberValue, base?: number) {
    if (isNil(n)) return

    return this.value.gt(BNumber.from(n).toFixed(), base)
  }

  lt(n: BNumberValue, base?: number): boolean
  lt(n?: BNumberValue, base?: number): boolean | undefined
  lt(n?: BNumberValue, base?: number) {
    if (isNil(n)) return

    return this.value.lt(BNumber.from(n).toFixed(), base)
  }

  lte(n: BNumberValue, base?: number): boolean
  lte(n?: BNumberValue, base?: number): boolean | undefined
  lte(n?: BNumberValue, base?: number) {
    if (isNil(n)) return

    return this.value.lte(BNumber.from(n).toFixed(), base)
  }

  gte(n: BNumberValue, base?: number): boolean
  gte(n?: BNumberValue, base?: number): boolean | undefined
  gte(n?: BNumberValue, base?: number) {
    if (isNil(n)) return

    return this.value.gte(BNumber.from(n).toFixed(), base)
  }

  eq(n: BNumberValue, base?: number): boolean
  eq(n?: BNumberValue, base?: number): boolean | undefined
  eq(n?: BNumberValue, base?: number) {
    if (isNil(n)) return

    return this.value.eq(BNumber.from(n).toFixed(), base)
  }

  minus(n: BNumberValue, base?: number): BNumber
  minus(n?: BNumberValue, base?: number): BNumber | undefined
  minus(n?: BNumberValue, base?: number) {
    if (isNil(n)) return

    return BNumber.from(this.value.minus(BNumber.from(n).toFixed(), base))
  }

  plus(n: BNumberValue, base?: number): BNumber
  plus(n?: BNumberValue, base?: number): BNumber | undefined
  plus(n?: BNumberValue, base?: number) {
    if (isNil(n)) return

    return BNumber.from(this.value.plus(BNumber.from(n).toFixed(), base))
  }

  decimalPlaces(): number
  decimalPlaces(decimalPlaces: number, roundingMode?: BigNumber.RoundingMode): BNumber
  decimalPlaces(decimalPlaces?: number, roundingMode?: BigNumber.RoundingMode) {
    if (isUndefined(decimalPlaces)) {
      return this.value.decimalPlaces()
    }

    return BNumber.from(this.value.decimalPlaces(decimalPlaces, roundingMode))
  }

  negated() {
    return BNumber.from(this.value.negated())
  }

  toFixed(): string
  toFixed(decimalPlaces: number, roundingMode?: BigNumber.RoundingMode): string
  toFixed(decimalPlaces?: number, roundingMode?: BigNumber.RoundingMode) {
    if (isUndefined(decimalPlaces)) {
      return this.value.toFixed()
    }

    return this.value.toFixed(decimalPlaces, roundingMode)
  }

  integerValue(rm?: BigNumber.RoundingMode) {
    return BNumber.from(this.value.integerValue(rm))
  }

  toNumber() {
    return this.value.toNumber()
  }

  abs() {
    return BNumber.from(this.value.abs())
  }

  toBigInt() {
    return BigInt(this.value.integerValue().toFixed())
  }


  /** Rounds away from zero. */
  static readonly ROUND_UP: 0

  /** Rounds towards zero. */
  static readonly ROUND_DOWN: 1

  /** Rounds towards Infinity. */
  static readonly ROUND_CEIL: 2

  /** Rounds towards -Infinity. */
  static readonly ROUND_FLOOR: 3

  /** Rounds towards nearest neighbour. If equidistant, rounds away from zero . */
  static readonly ROUND_HALF_UP: 4

  /** Rounds towards nearest neighbour. If equidistant, rounds towards zero. */
  static readonly ROUND_HALF_DOWN: 5

  /** Rounds towards nearest neighbour. If equidistant, rounds towards even neighbour. */
  static readonly ROUND_HALF_EVEN: 6

  /** Rounds towards nearest neighbour. If equidistant, rounds towards Infinity. */
  static readonly ROUND_HALF_CEIL: 7

  /** Rounds towards nearest neighbour. If equidistant, rounds towards -Infinity. */
  static readonly ROUND_HALF_FLOOR: 8

  /** See `MODULO_MODE`. */
  static readonly EUCLID: 9
}
