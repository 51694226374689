import { Link, useLocation, useParams } from 'react-router-dom'

export const Header = ({rate}: {rate: string}) => {

  let { pairSymbol = '' } = useParams()
  const [pairBaseSymbol, pairQuoteSymbol] = pairSymbol?.split('_')
  const { pathname } = useLocation()

  const parts = pathname.split('/').filter((part) => part)
  parts.pop()
  const parentPath = `/${parts.join('/')}`

  return (
    <div className="flex items-center gap-[60px]">
      <Link to={parentPath} replace={true} className=" cursor-pointer font-medium text-[15px] leading-[18px]">
        {'<'} All Markets
      </Link>

      <div className="flex items-center gap-[15px]">
        <div className="text-[18px] leading-[22px] text-white">{`${pairBaseSymbol} / ${pairQuoteSymbol}`}</div>
        <div className="text-[12px] font-medium leading-[17px] py-0.5 px-3 rounded-[4px]" style={{ background: Number(rate) > 0 ? '#009046' : '#009046' }}>{Number(rate) >= 0 ? '+' : ''}{rate}%</div>
      </div>
    </div>
  )
}
