import { rpcRequest } from "./api"


export type AssetInfo = {
  asset: string,
  asset_longname: string | null,
  description: string,
  divisible: boolean,
  issuer: string,
  locked: boolean,
  owner: string,
  supply: number,
}
export const fetchAssetsInfoApi = async (assetsList: string[]) => {
  const rs = await rpcRequest('get_assets_info', {
    assetsList
  })

  return rs as unknown as AssetInfo[]
}


type FetchAssetHistoryApiParams = {
  asset: string,
  reverse?: boolean
}

export enum AssetHistoryType {
  changed_description = 'changed_description',
  locked = 'locked',
  created = 'created',
  transferred = 'transferred',
  issued_more = 'issued_more'
}

export type AssetHistory = {
  type: AssetHistoryType.changed_description
  at_block: number
  at_block_time: number
  new_description: string
  prev_description: string
} | {
  type: AssetHistoryType.locked
  at_block: number
  at_block_time: number
} | {
  type: AssetHistoryType.created
  at_block: number
  at_block_time: number
  description: string
  divisible: boolean
  locked: boolean
  owner: string
  total_issued: number
  total_issued_normalized: number
} | {
  type: AssetHistoryType.transferred
  at_block: number
  at_block_time: number
  prev_owner: string
  new_owner: string
} | {
  type: AssetHistoryType.issued_more
  at_block: number
  at_block_time: number
  additional_normalized: number
  total_issued_normalized: number
}

  export const fetchAssetHistoryApi = async (params: FetchAssetHistoryApiParams) => {
    const rs = await rpcRequest('get_asset_history', {
      ...params
    })

    return rs as unknown as AssetHistory[]
  }
