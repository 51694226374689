import styles from './index.module.scss'

export type RadioItemType = {
  label: string,
  value: string,
  desc?: string,
  disabled?: boolean
}

const Radio = ({ items, value, onChange, name = 'abc' }: { items: RadioItemType[], value?: string, onChange: (value: string) => void, name?: string }) => (
  <div className={styles.wrap}>
    {
      items.filter(item => !item.disabled).map((item: RadioItemType) => {

        return (
          <div key={item.value}>
            <div className={styles.radioItem} onClick={() => onChange(item.value)}>
              <input type='radio' name={name} value={item.value} checked={value === item.value} />
              <div className={styles.radioLabel}>{item.label}</div>
            </div>
            {item.desc && <p className={styles.radioDesc}>{item.desc}</p>}
          </div>

        )
      })
    }
  </div>
);

export default Radio;
