import { TableFooter } from '@/components/TableFooter'
import { Table } from '@/components/ui/Table'
import { addressAtom } from '@/store'
import { cn } from '@/utils/cn'
import searchIcon from 'assets/icons/search.svg'
import {  useAtomValue } from 'jotai'
import { NavLink, useNavigate } from 'react-router-dom'
import { useRequest } from 'ahooks'
import { getMarketList, getTopPairs } from '@/request/api'
import { useState } from 'react'
import styles from './index.module.scss'

export const Market = () => {
  const [data, setData] = useState<any[]>([])
  const [marketList, setMarketList] = useState<any[]>([])
  const [searchMarket, setSearchMarket] = useState<string>('')
  const [token1, setToken1] = useState<string>('')
  const [isOther, setIsOther] = useState<boolean>(false)
  const [token2, setToken2] = useState<string>('')
  const navigate = useNavigate()
  const userAddress = useAtomValue(addressAtom)
  const [currentTableKey, setCurrentTableKey] = useState<string>('Market')

  useRequest(async () => {
    const rs = await getTopPairs([userAddress])

    setData(rs)
    return rs
  }, {
    ready: !!userAddress,
    refreshDeps: [userAddress],
  })


  useRequest(async () => await getMarketList(), {
    ready: !!userAddress,
    onSuccess: (res) => {
      setMarketList(res)
    }
  })


  return (
    <>
      <div className={styles.mobileContainer}>
        <div className={styles.subject}>Decentralized Exchange Markets</div>
        <div className={styles.desc}>
          Warning and Disclaimer: Every asset can be viewed on the "Markets" page. The hosts of Bitroot wallet.io do not filter very likely scam or fraudulent assets, and explicitly disclaim any
          responsibility therefor. Caveat emptor: do your own diligence, and do not invest more than you can afford to lose.{' '}
        </div>
        <div className={styles.topPairsWrap}>
          <p className={styles.topPairs_subject}>Top pairs</p>
          <div className={styles.topPairs}>
            {data?.map?.((item: any, index: number) => {
              return (
                <NavLink className={styles.pairItem} to={`./${item.base_asset === 'XCP' ? 'BRT' : item.base_asset}_${item.quote_asset === 'XCP' ? 'BRT' : item.quote_asset}`}>
                  <div
                    key={index}
                  >
                    {item.base_asset === 'XCP' ? "BRT" : item.base_asset} / {item.quote_asset === 'XCP' ? "BRT" : item.quote_asset}
                  </div>
                </NavLink>
              )
            })}
          </div>
        </div>
        <div className={styles.topPairsWrap}>
          <p className={styles.topPairs_subject}>Select Another pairs</p>
          <div className={styles.selectPairs}>
            <div className={styles.selectPairs_item}>
              <p className={styles.selectPairs_item_title}>Token1</p>
              <input className={styles.token1Input}
                placeholder='Assets1 Name'
                value={token1}
                onChange={(e) => setToken1(e.target.value)} />
            </div>
            <div className={styles.selectPairs_item}>
              <p className={styles.selectPairs_item_title}>Token2</p>
              <div className={styles.selectPairs_item_wrap}>
                <div className={styles.selectPairs_item_wrap_token} style={{ color: !isOther && token2 === 'BRT' ? '#009046' : 'white' }} onClick={() => {
                  setToken2('BRT')
                  setIsOther(false)
                }}>BRT</div>
                <div className={styles.selectPairs_item_wrap_token} style={{ color: isOther ? '#009046' : 'white' }} onClick={() => setIsOther(!isOther)}>Other</div>
              </div>
              {
                isOther &&
                <div className={styles.customWrap}>
                  <input
                    className={styles.customInput}
                    placeholder='Assets2 Name'
                    onChange={(e) => {
                      setToken2(e.target.value)
                    }}
                  />
                  <button
                    className={styles.detailButton}
                    onClick={() => {
                      if (token1 && token2) {
                        navigate(`./${token1}_${token2}`)
                      }
                    }}
                  >
                    Detail
                  </button>
                </div>
              }
            </div>
          </div>
        </div>

        <div className={styles.topPairsWrap}>
          <p className={styles.topPairs_subject}>All Pairs</p>
          <div className={styles.tableHeaders}>
            <p className={[styles.tableHeaderItem, currentTableKey === 'Market' ? styles.tableHeaderCurrentItem : ''].join(' ')} onClick={() => setCurrentTableKey('Market')}>Market</p>
            <p className={[styles.tableHeaderItem, currentTableKey === 'Price' ? styles.tableHeaderCurrentItem : ''].join(' ')} onClick={() => setCurrentTableKey('Price')}>Price</p>
            <p className={[styles.tableHeaderItem, currentTableKey === 'Volume' ? styles.tableHeaderCurrentItem : ''].join(' ')} onClick={() => setCurrentTableKey('Volume')}>24H Volume</p>
            <p className={[styles.tableHeaderItem, currentTableKey === 'Supply' ? styles.tableHeaderCurrentItem : ''].join(' ')} onClick={() => setCurrentTableKey('Supply')}>Supply</p>
            <p className={[styles.tableHeaderItem, currentTableKey === 'Cap' ? styles.tableHeaderCurrentItem : ''].join(' ')} onClick={() => setCurrentTableKey('Cap')}>Market Cap</p>
            <p className={[styles.tableHeaderItem, currentTableKey === 'Change' ? styles.tableHeaderCurrentItem : ''].join(' ')} onClick={() => setCurrentTableKey('Change')}>24H Change</p>
          </div>
          <div className={styles.tables}>
            {
              marketList?.filter(item => !searchMarket || item.base_asset.indexOf(searchMarket.toUpperCase()) > -1)?.map?.((item, key) => {
                const marketItem = {
                  id: item.pos,
                  Market: `${item.base_asset === 'XCP' ? 'BRT' : item.base_asset}/${item.quote_asset === 'XCP' ? 'BRT' : item.quote_asset}`,
                  Price: `${Number(item.price).toFixed(4)} BRT`,
                  '24h_Volume': `${Number(item.price_24h).toFixed(4)} ${item.quote_asset === 'XCP' ? 'BRT' : item.quote_asset}`,
                  Supply: `${item.supply / 1e8} ${item.quote_asset === 'XCP' ? 'BRT' : item.quote_asset}`,
                  Market_Cap: `${Number(item.market_cap) / 1e8} ${item.quote_asset === 'XCP' ? 'BRT' : item.quote_asset}`,
                  '24h_Change': `${item.progression}%`
                }
                const _market = marketItem.Market.split('/') ?? []

                return <div className={styles.marketItem} key={key}>
                  <NavLink to={`./${_market[0]}_${_market[1]}`
                  }>
                    <p className={styles.marketItem_text} title={`${_market[0]}_${_market[1]}`}>
                      {marketItem.Market}
                    </p>
                  </NavLink>
                  {
                    currentTableKey === 'Market' && <p className={styles.marketItem_text}>
                      {marketItem.Market}
                    </p>
                  }
                  {
                    currentTableKey === 'Price' && <p className={styles.marketItem_text}>
                      {marketItem.Price}
                    </p>
                  }
                  {
                    currentTableKey === 'Volume' && <p className={styles.marketItem_text}>
                      {marketItem['24h_Volume']}
                    </p>
                  }
                  {
                    currentTableKey === 'Supply' && <p className={styles.marketItem_text}>
                      {marketItem['Supply']}
                    </p>
                  }
                  {
                    currentTableKey === 'Cap' && <p className={styles.marketItem_text}>
                      {marketItem['Market_Cap']}
                    </p>
                  }
                  {
                    currentTableKey === 'Change' && <p className={styles.marketItem_text}>
                      {marketItem['24h_Change']}
                    </p>
                  }
                </div>
              })
            }
          </div>
        </div>
      </div>
      <div className={['max-w-[980px] mx-auto flex flex-col p-[38px] min-h-full text-white', styles.container].join(' ')}>
        <div className="font-medium text-[23px] leading-[27px]">Decentralized Exchange Markets</div>
        <div className="mt-2.5 text-[12px] leading-5 text-[#929292]">
          Warning and Disclaimer: Every asset can be viewed on the "Markets" page. The hosts of Bitroot wallet.io do not filter very likely scam or fraudulent assets, and explicitly disclaim any
          responsibility therefor. Caveat emptor: do your own diligence, and do not invest more than you can afford to lose.{' '}
        </div>

        <div className="mt-[30px] flex gap-[25px] w-full border-[red] flex">
          <div className="flex-1 rounded-[15px] bg-[#101417] p-5">
            <div className="font-medium text-[18px] leading-[22px]">Top pairs</div>

            <div className={cn('grid grid-cols-4 mt-3 bg-black rounded-[5px] min-w-[480px]')}>
              {data?.map?.((item: any, index: number) => {
                return (
                  <NavLink to={`./${item.base_asset === 'XCP' ? 'BRT' : item.base_asset}_${item.quote_asset === 'XCP' ? 'BRT' : item.quote_asset}`}>
                    <div
                      key={index}
                      className={cn('hover:text-[#00ff8e] text-small cursor-pointer border-[#101417] py-2 px-2.5 text-center whitespace-nowrap ', {
                        'border-r': true,
                        'border-b': true,
                      })}
                    >
                      {item.base_asset === 'XCP' ? "BRT" : item.base_asset} / {item.quote_asset === 'XCP' ? "BRT" : item.quote_asset}
                    </div>
                  </NavLink>
                )
              })}
            </div>
          </div>

          <div className="flex-1 flex flex-col rounded-[15px] bg-[#101417] p-5 w-[200px]">
            <div className="font-medium text-[18px] leading-[22px]">Select Another pair</div>
            <div className="flex flex-1 gap-5 py-2.5 px-4 mt-3 bg-black rounded-[5px]">
              <div className="flex-1">
                <div className="font-bold text-[12px] leading-[16px]">Token1</div>
                <input className="mt-4 border border-[#101417] text-[12px] w-[100px] rounded-[5px] py-2 px-2 bg-[transparent] outline-none"
                  placeholder='Assets1 Name'
                  value={token1}
                  onChange={(e) => setToken1(e.target.value)} />
              </div>
              <div className="flex-1 min-w-[180px] min-h-[100px]" >
                <div className="font-bold text-[12px] leading-[16px]">Token2</div>
                <div className="flex mt-4 border border-[#101417] rounded-[5px] ">
                  <div className="flex-1 text-center py-2 px-2 text-[12px]  border-r border-[#101417] cursor-pointer" style={{ color: !isOther && token2 === 'BRT' ? '#009046' : 'white' }} onClick={() => {
                    setToken2('BRT')
                    setIsOther(false)
                  }}>BRT</div>
                  <div className="flex-1 text-center py-2 px-2 text-[12px]" style={{ color: isOther ? '#009046' : 'white' }} onClick={() => setIsOther(!isOther)}>Other</div>
                </div>
                <div className='flex items-center	gap-4 justify-end mt-[10px] pr-[10px]'>
                  {
                    isOther && <input
                      className="mt-4 border border-[#101417] text-[12px] w-[100px] rounded-[5px] py-2 px-2 bg-[transparent] outline-none"
                      placeholder='Assets2 Name'
                      onChange={(e) => {
                        setToken2(e.target.value)
                      }}
                    />
                  }
                  <button
                    className='border-[#101417] bg-white text-[12px]'
                    style={{ color: 'black', padding: '0 10px', height: '36px', borderRadius: '6px', marginTop: '15px', fontSize: '12px' }}
                    onClick={() => {
                      if (token1 && token2) {
                        navigate(`./${token1}_${token2}`)
                      }
                    }}
                  >
                    Detail
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col mt-[20px] rounded-[15px] bg-[#101417] p-5">
          <div className="flex justify-between">
            <div className="font-medium text-[18px] leading-[22px]">All Pairs</div>

            <div className="min-w-[200px] items-center gap-3 flex py-2.5 px-4 bg-black rounded-[5px] border border-[#101417]">
              <div>
                <img src={searchIcon} className="size-[12px]" alt="" />
              </div>

              <input className="flex-1 bg-transparent outline-none text-[12px] leading-[17px] font-medium text-[#929292]" onChange={(e) => setSearchMarket(e.target.value)} />
            </div>
          </div>
          <div className="flex-1 bg-black rounded-[5px] mt-[18px]">
            <Table
              columns={[
                { dataIndex: 'id', label: '#' },
                {
                  dataIndex: 'Market', label: 'Market', render: (row) => {
                    const market = row.Market.split('/') ?? []

                    return (
                      <NavLink to={`./${market[0]}_${market[1]}`
                      }>
                        <p className='w-[100px] whitespace-nowrap truncate underline' title={`${market[0]}_${market[1]}`}>
                          {row.Market}
                        </p>
                      </NavLink>
                    )
                  }
                },
                { dataIndex: 'Price', label: 'Price' },
                { dataIndex: '24h_Volume', label: '24h Volume' },
                { dataIndex: 'Supply', label: 'Supply' },
                { dataIndex: 'Market_Cap', label: 'Market Cap' },
                { dataIndex: '24h_Change', label: '24h Change' },
              ]}
              data={marketList?.filter(item => !searchMarket || item.base_asset.indexOf(searchMarket.toUpperCase()) > -1)?.map?.((item: any) => ({
                id: item.pos,
                Market: `${item.base_asset === 'XCP' ? 'BRT' : item.base_asset}/${item.quote_asset === 'XCP' ? 'BRT' : item.quote_asset}`,
                Price: `${Number(item.price).toFixed(4)} BRT`,
                '24h_Volume': `${Number(item.price_24h).toFixed(4)} ${item.quote_asset === 'XCP' ? 'BRT' : item.quote_asset}`,
                Supply: `${item.supply / 1e8} ${item.quote_asset === 'XCP' ? 'BRT' : item.quote_asset}`,
                Market_Cap: `${Number(item.market_cap) / 1e8} ${item.quote_asset === 'XCP' ? 'BRT' : item.quote_asset}`,
                '24h_Change': `${item.progression}%`
              }))}
              noData={<TableFooter />}
            />
          </div>
        </div>
      </div >
    </>

  )
}
