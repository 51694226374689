import { useRequest } from "ahooks"
import { BuyOrdersBook } from "./buy"
import { SellOrdersBook } from "./sell"
import { getMarketDetail } from "@/request/api"
import { useParams } from "react-router-dom"
import { useState } from "react"

export const OrderBook = () => {
  let { pairSymbol = '' } = useParams()
  const [pairBaseSymbol, pairQuoteSymbol] = pairSymbol?.split('_')

  const [sellOrders, setSellOrder] = useState<any[]>([])
  const [buyOrders, setBuyOrder] = useState<any[]>([])

  useRequest(async () => await getMarketDetail(pairBaseSymbol, pairQuoteSymbol), {
    ready: !!pairBaseSymbol && !!pairQuoteSymbol,
    refreshDeps: [pairBaseSymbol, pairQuoteSymbol],
    onSuccess: (res: any) => {
      setSellOrder(res?.sell_orders ?? [])
      setBuyOrder(res?.buy_orders ?? [])
    }
  })

  return <div className="flex gap-6 flex-wrap mt-[10px]">
    <SellOrdersBook orders={sellOrders} />
    <BuyOrdersBook orders={buyOrders} />
  </div>
}
