import { useAtomValue, useSetAtom } from 'jotai'
import styles from './index.module.scss'
import avatar from 'assets/images/header/avatar.svg'
import { addressAtom, showMobileMenuAtom } from '@/store'
import { connectWallet, encryptionAddress } from '@/utils'
import { useNavigate } from 'react-router-dom'
import Menu from 'assets/images/header/Menu.svg'

const User = () => {
    const userAddress = useAtomValue(addressAtom)
    const navigate = useNavigate()

    if (!userAddress) {
        return <div className={styles.user} onClick={async () => {
            try {
                await connectWallet()
                navigate('/balance')
            } catch (e) {
                console.log(e)
            }
        }}>
            Connect Wallet
        </div>
    }

    return <div className={styles.user}>
        {userAddress ? encryptionAddress(userAddress.toUpperCase()) : 'Create Account'}
    </div>
}

export const Account = () => {
    const setShowMobileMenu = useSetAtom(showMobileMenuAtom)
    const userAddress = useAtomValue(addressAtom)
    return (
        <div className={styles.account}>
            <div className={styles.accountWrap}>
                <img src={avatar} alt='avatar' className={styles.avatar} />
                <User />
            </div>
            <img className={styles.menu} src={Menu} onClick={() => {
                if(!userAddress) return false
                setShowMobileMenu(true)
            }} />
        </div>
    )
}