import type { LucideIcon } from 'lucide-react'
import {
  ShieldCheck,
  ChevronLeft,
  ChevronRight,
  Github,
  Loader2,
  Menu,
  Moon,
  Rocket,
  SunMedium,
  User,
  X,
  Settings,
  MoveLeft,
} from 'lucide-react'

export type Icon = LucideIcon

export const Icons = {
  MoveLeft,
  logo: Rocket,
  close: X,
  Spinner: Loader2,
  ChevronLeft,
  ChevronRight,
  User,
  Sun: SunMedium,
  Moon,
  Github,
  Menu,
  Settings,
  ShieldCheck,
}
